const XIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className="mr-2"
    >
      <path
        d="M14.0167 17.3067L3.12755 3.30667C3.07016 3.23276 3.03465 3.14423 3.02508 3.05114C3.01551 2.95806 3.03225 2.86415 3.07341 2.78011C3.11456 2.69607 3.17847 2.62526 3.25787 2.57575C3.33727 2.52623 3.42897 2.49998 3.52255 2.5H5.58922C5.6653 2.50011 5.74035 2.51759 5.80865 2.55109C5.87696 2.58459 5.93672 2.63324 5.98338 2.69333L16.8725 16.6933C16.9299 16.7672 16.9654 16.8558 16.975 16.9489C16.9846 17.0419 16.9678 17.1358 16.9267 17.2199C16.8855 17.3039 16.8216 17.3747 16.7422 17.4243C16.6628 17.4738 16.5711 17.5 16.4775 17.5H14.4109C14.3348 17.4999 14.2598 17.4824 14.1914 17.4489C14.1231 17.4154 14.0634 17.3668 14.0167 17.3067Z"
        stroke="#202020"
        strokeWidth="1.25"
      />
      <path
        d="M16.6667 2.5L3.33337 17.5"
        stroke="#202020"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default XIcon;
