import { useCallback, useEffect, useState } from "react";

/**
 * Keeps track of the windows inner width and height dimensions.
 * @param handleResize: function to execute when window is resized.
 * @returns
 * @see windowDimensions: the current window dimensions.
 */
export const useWindowDimensions = (
  handleResize,
) => {
  const [windowDims, setWindowDims] = useState({
    width: 0,
    height: 0,
  });

  const onResize = useCallback(() => {
    const width = window.visualViewport?.width || window.innerWidth;
    const height = window.visualViewport?.height || window.innerHeight;

    setWindowDims({
      width,
      height,
    });
    handleResize?.({ width, height });
  }, [handleResize]);

  useEffect(() => {
    // call initially on window load as the resize event isn't called on load
    onResize();

    if (window.visualViewport !== undefined) {
      window.visualViewport?.addEventListener("resize", onResize);
    } else {
      window.addEventListener("resize", onResize);
    }

    return () => {
      window.removeEventListener("resize", onResize);
      window.visualViewport?.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  return windowDims;
};
