import React from "react";
import Heart from "./heartIcon"
import Credit from "./credit"

const Footer = () => {
  return (
    <div
      className={"flex w-full text-sm md:text-base bg-[#202020] justify-start items-center p-[16px] sm:p-[18px] rounded-[40px] flex-wrap"}
    >
      <div className="flex text-[#F0FFF1] flex-wrap items-center justify-start">
        Made with{"  "}<Heart />{"  "}by
        <Credit href="https://x.com/old_samster" name="Sami" />
        ,
        <Credit href="https://x.com/WOMBO" name="WOMBO" />
        , and
        <Credit href="https://x.com/NothingLabs" name="Nothing Labs" />
      </div>
    </div>
  );
};

export default Footer;
