import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useBlogPosts } from "./blogPosts";
import CarouselBlogCard from "./carouselBlogCard";
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import Marquee from "react-fast-marquee";

const BlogCarousel = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const blogPosts = useBlogPosts();

  const slidesPerView = useMemo(() => {
    if(width < 600) {
      return 1.2 
    } 
    return 3
  }, [width])

  // duplicate blog posts until there are at least 5
  const duplicatedBlogPosts = [...blogPosts, ...blogPosts, ...blogPosts];
  const centeredSlides = slidesPerView < 3; 

  return (
    <div className="w-full md:h-full h-[115px] flex items-center">
      {/* Wrapper for Carousel and Button */}
      <div className="relative w-full h-full flex items-center">
        {/* Carousel Container */}
        <Marquee pauseOnHover={true} className="h-full">
          {duplicatedBlogPosts.map((post, index) => (
            <CarouselBlogCard
              key={index}
              image={post.image}
              title={post.title}
              description={post.description}
              onClick={() => navigate(`/blog/${post.id}`)}
            />
          ))}
        </Marquee>

        {/* Right Arrow Scroll Button */}
        {duplicatedBlogPosts.length > 2 && !centeredSlides && (
          <button
            className="absolute right-0 bottom-1/2 transform translate-y-1/2 z-20 flex flex-col items-center justify-center w-24 h-full bg-[#202020] text-white rounded-[32px] shadow-lg transition-transform duration-300"
            style={{
              boxShadow: '0px 10px 50px 10px rgba(0, 0, 0, 0.7)',
            }}
            onClick={() => navigate("/blog")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#7E807D"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="w-6 h-6 mb-2"
            >
              <path d="M5 12h14M12 5l7 7-7 7" />
            </svg>
            <span className="text-md text-[#DCE0D9]">
              Blog
            </span>
          </button>
        )}
      </div>
    </div>
  );
};

export default BlogCarousel;
