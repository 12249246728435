import "../index.css";
import React, { useEffect, useState } from "react";
import CsvToJson from "../data/index.js";
import { useBlogPosts } from "../components/blogs/blogPosts.js";
import Header from "../components/header.js";
import Achievements from "../components/achievements.js";
import Map from "../components/map.js";
import PopOut from "../components/popout.js";
import BaseMobile from "../components/mobile/basemobile.js";
import Footer from "../components/footer";
import BlogCarousel from "../components/blogs/blogCarousel.js";
import ResourcesBanner from "../components/resources/resourcesBanner.js";

const Home = () => {
  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const [data, setData] = useState(null);
  const [achievements, setAchievements] = useState(null);
  const [selected, setSelected] = useState(false);
  const [showResourcesBanner, setShowResourcesBanner] = useState(true);
  const blogPosts = useBlogPosts();

  useEffect(() => {
    CsvToJson(
      "https://raw.githubusercontent.com/nothinglabsorg/datapill/refs/heads/main/subnets.csv"
    ).then((parsedData) => {
      setData(parsedData);
    });

    CsvToJson(
      "https://raw.githubusercontent.com/nothinglabsorg/datapill/refs/heads/main/achievements.csv"
    ).then((parsedAchievements) => {
      setAchievements(parsedAchievements);
    });
  }, []);

  return (
    <div className="flex w-full min-h-screen bg-black flex-col">
      <Header />

      {/* Resources Banner */}
      {/* showResourcesBanner && (
        <div className="w-full md:h-40 h-18 mt-1">
          <ResourcesBanner onClose={() => setShowResourcesBanner(false)} />
        </div>
      )*/}

      {/* Blog Posts Carousel (show only if there are blog posts) */}
      {blogPosts.length > 0 ? (
        <div className="w-full md:h-40 h-18 mt-1">
          <BlogCarousel />
        </div>
      ) : null}

      <div
        className={
          "hidden lg:flex w-full flex-grow my-1 " +
          (isSafari ? null : "md:overflow-hidden")
        }
      >
        {data ? (
          <Map data={data} selected={selected} setSelected={setSelected} />
        ) : null}
        {achievements
          ? [
              selected ? (
                <PopOut
                  subnet_data={data.find(
                    (item) => item["Subnet Name"] === selected
                  )}
                  achievement_data={achievements.filter(
                    (the_item) =>
                      typeof the_item["Subnet Name"] === "string" &&
                      the_item["Subnet Name"].toLowerCase() ===
                        data
                          .find((item) => item["Subnet Name"] === selected)
                          ["Subnet Title"].toLowerCase()
                  )}
                  setSelected={setSelected}
                />
              ) : (
                <Achievements data={achievements} />
              ),
            ]
          : null}
      </div>
      <div
        className={
          "flex lg:hidden w-full flex-grow my-1 md:my-1 " +
          (selected ? "overflow-hidden" : null)
        }
      >
        {selected ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              backdropFilter: "blur(2.5px)",
              zIndex: 10,
              pointerEvents: "none",
            }}
          />
        ) : null}
        {data && achievements ? (
          <BaseMobile
            data={data}
            achievements={achievements}
            selected={selected}
            setSelected={setSelected}
          ></BaseMobile>
        ) : null}
      </div>
      <Footer />
    </div>
  );
};

export default Home;
