const HeartIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className="mx-1"
    >
      <path
        d="M1.66663 7.61398C1.66663 11.6665 5.01663 13.8256 7.46829 15.759C8.33329 16.4406 9.16663 17.0831 9.99996 17.0831C10.8333 17.0831 11.6666 16.4415 12.5316 15.7581C14.9841 13.8265 18.3333 11.6665 18.3333 7.61481C18.3333 3.56314 13.75 0.687309 9.99996 4.58398C6.24996 0.687309 1.66663 3.56148 1.66663 7.61398Z"
        fill="#DF367C"
      />
    </svg>
  )
}

export default HeartIcon;
