import { useEffect, useState } from "react";
import Papa from "papaparse";

const defaultTitle = "Post Coming Soon";
const defaultDescription =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tempus tristique iaculis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tempus tristique iaculis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tempus tristique iaculis.";
const defaultImage =
  "https://images.unsplash.com/photo-1709712268485-09135211ac53?q=80&w=2668&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

const fetchCSVData = async (path) => {
  const response = await fetch(path);
  const csvText = await response.text();
  return new Promise((resolve, reject) => {
    Papa.parse(csvText, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => resolve(result.data),
      error: (error) => reject(error),
    });
  });
};

const fetchMarkdownDescription = async (markdownPath) => {
  const response = await fetch(markdownPath);
  const content = await response.text();

  // Split content into lines, ignore title (starting with "#") and any line containing "Author:"
  const filteredLines = content
    .split("\n")
    .filter(line => !line.startsWith("#") && !line.toLowerCase().includes("authors:") && !line.toLowerCase().includes("author:"))
    .slice(0, 10); // Take first 10 relevant lines

  // Join lines and remove markdown characters
  const plainText = filteredLines.join(" ").replace(/[#_*>\-\[\]\(\)`~]/g, "").trim();

  return plainText || defaultDescription; // Fallback to defaultDescription if empty
};

export const useBlogPosts = () => {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const loadBlogPosts = async () => {
      try {
        const csvData = await fetchCSVData(
          process.env.REACT_APP_BLOGS_URL 
        );

        const postsWithDescriptions = await Promise.all(
          csvData.map(async (post) => {
            const description =
              post.markdownPath && post.markdownPath !== ""
                ? await fetchMarkdownDescription(post.markdownPath)
                : defaultDescription;

            return {
              id: post.id,
              title: post.title || defaultTitle,
              description: post.description || description || defaultDescription,
              image: post.image || defaultImage,
              markdownPath: post.markdownPath,
            };
          })
        );

        setBlogPosts(postsWithDescriptions);
      } catch (error) {
        console.error("Failed to load blog posts:", error);
      }
    };

    loadBlogPosts();
  }, []);

  return blogPosts;
};
