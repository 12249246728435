import "../index.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/header.js";
import Footer from "../components/footer";
import { useBlogPosts } from "../components/blogs/blogPosts.js";

const BlogCard = ({ key, post, onClick }) => {
  return (
    <div
      key={key}
      className="bg-[#202020] rounded-[32px] shadow-lg hover:scale-105 transition-transform duration-300 overflow-hidden m-3 cursor-pointer h-[65vh]"
      onClick={onClick}
    >
      {/* Image Section */}
      <div
        className="h-[60%] p-6 flex justify-center items-center"
      >
        <img
          src={post.image}
          alt={post.title}
          className="w-full h-full object-cover rounded-[20px]"
        />
      </div>

      {/* Title and Description Section */}
      <div className="h-[40%] p-6 text-left">
        <h3 className="text-2xl text-[#DCE0D9] mb-2 line-clamp-1 md:line-clamp-2">
          {post.title}
        </h3>
        <p className="text-md text-[#9A9D98] line-clamp-5 md:line-clamp-6">
          {post.description || "Description coming soon..."}
        </p>
      </div>
    </div>
  );
};

const Blog = () => {
  const navigate = useNavigate();
  const blogPosts = useBlogPosts();

  return (
    <div className="flex w-full min-h-screen bg-black flex-col">
      <Header />

      <div className="flex-grow my-1 flex w-full overflow-hidden">
        <div
          className="bg-[#A79AB2] rounded-[24px] md:rounded-[44px] w-full mx-auto flex flex-col overflow-hidden"
        >
          {/* Header Section */}
          <div className="flex items-center space-x-4 p-[32px]">
            <button
              className="hover:scale-105 transition-transform duration-300"
              onClick={() => navigate("/")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#202020"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="w-8 h-8"
              >
                <path d="M19 12H5M12 19l-7-7 7-7" />
              </svg>
            </button>
            <h1 className="text-4xl text-[#202020]">Blog</h1>
          </div>

          <hr className="border-[#7F7586] border-1 opacity-20" />

          {/* Scrollable Blog Posts Section */}
          <div className="flex-grow overflow-y-auto px-8 py-6 no-scrollbar">
            <div className="grid grid-cols-1 md:grid-cols-2">
              {blogPosts.map((post, index) => (
                <BlogCard key={index} post={post} onClick={() => navigate(`/blog/${post.id}`)} />
              ))}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Blog;
