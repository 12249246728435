import XIcon from "./xIcon"

const Credit = ({ href, name }) => {
  return (
    <a
      className="flex p-2 bg-[#A69F98] rounded-xl text-[#202020] hover:bg-[#5603AD] ml-1 mr-0.5 items-center justify-center"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <XIcon />
      <p className="flex transform transition-transform duration-400 hover:-translate-x-0.5"> 
        {name}
      </p>
    </a>
  )
}


export default Credit
