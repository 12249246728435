import React from "react";

const CarouselBlogCard = ({ index, image, title, description, onClick }) => (
  <div
    key={index}
    className="w-[250px] md:w-[630px] h-[115px] md:h-[160px] bg-[#202020] rounded-[32px] shadow-md flex-shrink-0 cursor-pointer flex md:p-4 gap-4 items-center md:px-8 p-2 px-4 mx-[4px]"
    onClick={onClick}
  >
      <img
        src={image}
        alt={title}
        className="min-w-[40%] w-[40%] h-[80px] md:h-[110px] object-cover rounded-[20px]"
      />
    <div className="w-3/5 flex flex-col justify-start h-full pt-3">
      <h3 className="text-sm text-[#DCE0D9] line-clamp-1">{title}</h3>
      <p className="text-sm text-[#9A9D98] line-clamp-3">{description}</p>
    </div>
  </div>
);

export default CarouselBlogCard;
