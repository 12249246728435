import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useBlogPosts } from "../components/blogs/blogPosts.js";
import ReactMarkdown from "react-markdown";
import Header from "../components/header.js";
import Footer from "../components/footer";

// Carousel Blog Card for the other blog posts - similar to the one on the home page but different styling
const CarouselBlogCard = ({ post, onClick }) => (
  <div
    className="w-full h-[125px] bg-[#202020] rounded-[28px] shadow-md flex flex-shrink-0 overflow-hidden cursor-pointer"
    onClick={onClick}
  >
    <div className="w-1/2 p-3 flex justify-center items-center">
      <img
        src={post?.image}
        alt={post?.title}
        className="w-full h-5/6 object-cover rounded-[20px] ml-4"
      />
    </div>
    <div className="w-1/2 flex flex-col justify-center py-3 pl-1 pr-6">
      <h3 className="text-sm text-[#DCE0D9] line-clamp-1">{post?.title}</h3>
      <p className="text-sm text-[#9A9D98] line-clamp-3">
        {post?.description}
      </p>
    </div>
  </div>
);

const BlogPost = () => {
  const { id } = useParams(); // Get the blog ID from the URL
  const navigate = useNavigate();
  const blogPosts = useBlogPosts();
  const [markdownContent, setMarkdownContent] = useState("");

  const blog = blogPosts.find((post) => post.id === id);

  useEffect(() => {
    if (blog && blog.markdownPath) {
      fetch(blog.markdownPath)
        .then((response) => response.text())
        .then((content) => setMarkdownContent(content))
        .catch((error) => console.error("Failed to load markdown: ", error));
    }
  }, [blog]);

  return (
    <div className="flex w-full min-h-screen bg-black flex-col">
      <Header />

      <div className="flex-grow my-1 flex w-full overflow-hidden">
        <div className="flex md:w-3/4 w-full">
          <div className="bg-[#202020] rounded-[24px] md:rounded-[44px] w-full mx-auto flex flex-col overflow-hidden">
            {/* Back Button and Title */}
            <div className="flex items-center space-x-4 p-[32px]">
              <button
                className="hover:scale-105 transition-transform duration-300"
                onClick={() => navigate(-1)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#DCE0D9"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="w-8 h-8"
                >
                  <path d="M19 12H5M12 19l-7-7 7-7" />
                </svg>
              </button>
              <h1 className="text-3xl md:text-4xl line-clamp-2 text-[#DCE0D9]">{blog?.title}</h1>
            </div>

            <hr className="border-[#7F7586] border-1 opacity-20" />

            {/* Markdown Content */}
            <div className="p-8 overflow-y-auto no-scrollbar">
              {/* Show the blog image */}
              <img src={blog?.image} alt={blog?.title} className="w-full max-w-[1000px] h-auto rounded-[20px] mb-8" />
              {/* Render the markdown content */}
              <ReactMarkdown
                className="prose prose-invert lg:prose-xl max-w-none text-[#B4B8AB]"
                children={markdownContent}
              />
            </div>
          </div>
        </div>


        {/* Other Blog Posts (show a list with each carousel blog card taking up full width) */}
        <div className="md:w-1/4 w-0 ml-1 overflow-y-auto no-scrollbar">
          <div className="flex flex-col space-y-1">
            {blogPosts
              .filter((otherPost) => otherPost.id !== id)
              .map((post) => (
                <CarouselBlogCard key={post.id} post={post} onClick={() => navigate(`/blog/${post.id}`)} />
              ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogPost;
